export default class ErrorUtil {
  static get ERRORS() {
    return {
      EB00999: {tech: 'MODULE_DISABLED'},
      EB00369: {tech: 'AMOUNT_INVALID_FRACTION'},
      PE00001: {tech: 'SECTOR_ID_INVALID'},
      PE00100: {tech: 'PERMIT_NOT_FOUND'},
      PE00101: {tech: 'PERMIT_INVALID_STATUS'},
      PE00102: {tech: 'PERMIT_EXPIRED'},
      PE00154: {tech: 'POSITION_MISSING'},
      PE00155: {tech: 'POSITION_NOT_FOUND'},
      PE00200: {tech: 'EXPORTER_NOT_MATCH'},
      PE00252: {tech: 'DESTINATION_NOT_MATCH'},
      PE00312: {tech: 'PERMIT_LATIN_NAME_MISSING'},
      PE00313: {tech: 'PERMIT_LATIN_NAME_NOT_MATCH'},
      PE00350: {tech: 'PERMIT_AMOUNT_MISSING'},
      PE00357: {tech: 'PERMIT_AMOUNT_EXCEEDED'},
      PE00360: {tech: 'PERMIT_AMOUNT_INVALID'},
      PE00362: {tech: 'PERMIT_UNIT_INVALID'},
      PE00404: {tech: 'USAGE_ID_EXISTS'},
      PE00999: {tech: 'UNKNOWN_EXCEPTION'}
    }
  }

  static get NON_BREAKING_ERRORS() {
    return [
      'EB00999'
    ]
  }

  static ITEMS(errors) {
    return errors.map(err => ({
        value: err,
        name: ErrorUtil.ERRORS[err]?.tech ?? err
      })
    )
  }

  static description(error, request) {
    return translateText(this.descriptions, error, request)
  }

  static get descriptions() {
    return {
      de: {
        EB00999: request => `Das Modul ${request.module} ist deaktiviert.`,
        EB00369: 'Bei den Einheiten PAIRS und PIECES dürfen nur ganze Zahlen verwendet werden.',
        PE00001: 'Der Restriction Code ist unbekannt',
        PE00100: 'Die Bewilligungsnummer ist unbekannt. Bitte überprüfen Sie die Bewilligungsnummer und ob der richtige Restriction Code ausgewählt wurde.',
        PE00101: 'Die Bewilligung ist zum jetzigen Zeitpunkt noch nicht gültig.',
        PE00102: 'Die Bewilligung ist zum jetzigen Zeitpunkt nicht mehr gültig.',
        PE00154: 'Die Bewilligungspositionsnummer fehlt.',
        PE00155: 'Die Bewilligungspositionsnummer ist ungültig. Die Bewilligung weist diese Position nicht auf.',
        PE00200: 'Die Angaben zum Bewilligungsinhaber stimmen nicht mit der Bewilligung überein.',
        PE00252: 'Das Bestimmungsland ist nicht unter den erlaubten Ländern der Bewilligung.',
        PE00312: 'Die fachspezifische Bezeichnung zur Ware ("Spezifikation der Ware") fehlt.',
        PE00313: 'Die angemeldete fachspezifische Bezeichnung ("Spezifikation der Ware") der zuständigen Stelle resp. deren ID (NAE Produkt-ID) ist auf der Bewilligung nicht vorgesehen.',
        PE00350: 'Die Bewilligung hat eine Mengenbeschränkung. Die erforderliche Mengenangabe in der Einheit, welche in der Bewilligung definiert ist, fehlt.',
        PE00357: 'Die angemeldete "Abzuschreibende Menge" übersteigt die noch vorhandene Restmenge der Bewilligung.',
        PE00360: 'Die abzuschreibende Menge ist ungültig. Bitte verwenden Sie das gleiche Format wie auf der Bewilligung und geben Sie die Menge ohne Einheit an.',
        PE00362: 'Die Einheit der abzuschreibenden Menge ist in der Bewilligung nicht vorgesehen.',
        PE00404: 'Das CITES-Zertifikat wurde bereits verwendet.',
        PE00999: 'Unbekannter Fehler.'
      },
      fr: {
        EB00999: request => `Le module ${request.module} est désactivé.`,
        EB00369: 'Pour les unités PAIRS et PIECES, seuls des nombres entiers peuvent être utilisés.',
        PE00001: 'Le code de restriction est inconnu.',
        PE00100: 'Le numéro d\'autorisation fourni n\'est pas valide. Veuillez vérifier le numéro d\'autorisation ainsi que le Restriction Code.',
        PE00101: 'L\'autorisation n\'est pas encore valable.',
        PE00102: 'L\'autorisation n\'est plus valable.',
        PE00154: 'Numéro de position de l\'autorisation manquant.',
        PE00155: 'Le numéro de position de l\'autorisation n\'est pas valable. L\'autorisation ne mentionne pas cette position.',
        PE00200: 'Les données relatives au titulaire de l\'autorisation ne correspondent pas à l\'autorisation.',
        PE00252: 'Le pays de destination ne fait pas partie des pays autorisés par l\'autorisation.',
        PE00312: 'La désignation spécifique à la marchandise ("spécification de la marchandise") manque.',
        PE00313: 'La désignation spécifique déclarée ("spécification de la marchandise") de l\'autorité compétente ou son ID (NAE Product-ID) n\'est pas prévue dans l\'autorisation.',
        PE00350: 'L\'autorisation comporte une limitation de quantité. La quantité requise dans l\'unité définie dans l\'autorisation manque.',
        PE00357: 'La "quantité à décharger" déclarée dépasse la quantité restante de l\'autorisation.',
        PE00360: 'La quantité à décharger n\'est pas valable. Veuillez utiliser le même format que sur l\'autorisation et indiquer la quantité sans unité.',
        PE00362: 'L\'unité de la quantité à décharger n\'est pas prévue dans l\'autorisation.',
        PE00404: 'Le certificat CITES a déjà été utilisé.',
        PE00999: 'Erreur inconnue.'
      },
      it: {
        EB00999: request => `Il modulo ${request.module} è disattivato.`,
        EB00369: 'Per le unità PAIRS e PIECES possono essere utilizzati solo numeri interi.',
        PE00001: 'Il Restriction Code non è stato riconosciuto',
        PE00100: 'Il numero di autorizzazione non è stato riconosciuto. Verifichi il numero di autorizzazione così come che il Restriction Code selezionato sia corretto.',
        PE00101: 'L\'autorizzazione non è ancora valida.',
        PE00102: 'L\'autorizzazione non è più valida.',
        PE00154: 'Manca il numero della posizione dell\'autorizzazione.',
        PE00155: 'Il numero della posizione dell\'autorizzazione non è valido.',
        PE00200: 'I dati relativi al titolare dell\'autorizzazione non corrispondono alle informazioni indicate nell\'autorizzazione.',
        PE00252: 'Il Paese di destinazione non rientra tra i Paesi ammessi nell\'autorizzazione.',
        PE00312: 'Manca la designazione della merce dal punto di vista professionale ("Specifiche della merce").',
        PE00313: 'La designazione dal punto di vista professionale ("Specifiche della merce")  o l\'ID dell\'autorità competente dichiarato (ID prodotto NAE), non sono previste nell\'autorizzazione.',
        PE00350: 'L\'autorizzazione prevede restrizioni quantitative. Nella dichiarazione delle merci mancano le informazioni sulla quantità specificata nell\'autorizzazione.',
        PE00357: 'La "quantità da scaricare" dichiarata supera la quantità disponibile nell\'autorizzazione.',
        PE00360: 'La quantità da scaricare non è valida. La preghiamo di utilizzare lo stesso formato dell\'autorizzazione e di indicare la quantità senza l\'unità.',
        PE00362: 'L\'unità della quantità da scaricare non é prevista nell\'autorizzazione.',
        PE00404: 'Il certificato CITES è già stato utilizzato.',
        PE00999: 'Errore sconosciuto.'
      }
    }
  }
}
