import GenericColumn from '@isceco/widget-library2/basic-elements/List/column/GenericColumn.js'
import DateTimeCell from '@isceco/widget-library2/basic-elements/List/cell/DateTimeCell.js'
import StringColumn from '@isceco/widget-library2/basic-elements/List/column/StringColumn.js'
import CheckboxColumn from '@isceco/widget-library2/basic-elements/List/column/CheckboxColumn.js'
import DropdownMultiselectColumn from '@isceco/widget-library2/basic-elements/List/column/DropdownMultiselectColumn.js'
import ModuleEnum from '../../enum/ModuleEnum.js'

export default class ModuleColumns {
  static getColumns() {
    return [
      this.DATE_TIME(),
      this.BY_USER(),
      this.MODULE(),
      this.ACTIVE()
    ]
  }

  static DATE_TIME() {
    return new GenericColumn({
      key: 'dateTime',
      text: 'table.header.dateTime',
      cell: new DateTimeCell(),
      sortable: true
    })
  }

  static BY_USER() {
    return new StringColumn({
      key: 'byUser',
      text: 'table.header.byUser',
      sortable: true
    })
  }

  static MODULE() {
    return new DropdownMultiselectColumn({
      key: 'module',
      text: 'table.header.module',
      items: ModuleEnum.ITEMS(),
      sortable: true
    })
  }

  static ACTIVE() {
    return new CheckboxColumn({
      key: 'active',
      text: 'table.header.active',
      disabled: _ => true,
      sortable: true
    })
  }
}
