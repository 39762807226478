import WebComponent from '../../../WebComponent.js'
import {html} from '@isceco/widget-library2/external/lit'
import QueriesColumns from '../../Queries/QueriesColumns.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/CheckboxFilter.js'
import QueriesService from '../../../services/QueriesService.js'
import QueriesRowVariant from '../../Queries/QueriesRowVariant.js'
import ModuleEnum from '../../../enum/ModuleEnum.js'
import QueriesListService from '../../Queries/QueriesListService.js'
import TypeEnum from '../../../enum/TypeEnum.js'
import AnfrageStatusEnum from '../../../enum/AnfrageStatusEnum.js'
import ErrorUtil from '../../../services/ErrorUtil.js'
import VerarbeitungStatusEnum from '../../../enum/VerarbeitungStatusEnum.js'
import CommentCell from '../../Queries/CommentCell.js'

export default class CITESExport extends WebComponent {
  get translationFile() {
    return './views/CITESExport/Queries/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.module = ModuleEnum.KEYS.CITES_EXPORT
    this.reload()
  }

  reload() {
    const queriesService = new QueriesService()

    Promise.all([
      queriesService.getBackendListUrl(),
      queriesService.listByModule('destinations', this.module),
      queriesService.listByModule('responseerrors', this.module)
    ])
      .then(([url, destinations, errors]) => {
        this.listUrl = url
        this.destinations = destinations.map(destination => ({
          value: destination,
          name: destination
        }))
        this.errors = ErrorUtil.ITEMS(errors)
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.title')}"></isceco-title>

      <isceco-filter-group>
        <isceco-date-filter
          label="${this.i18n.translate('table.header.time')}"
          key="time">
        </isceco-date-filter>
        <isceco-string-filter
          label="${this.i18n.translate('table.header.permitId')}"
          key="permitId"
          minchars="2">
        </isceco-string-filter>
        <isceco-multiselect-filter
          .items="${this.destinations}"
          label="${this.i18n.translate('table.header.destination')}"
          key="destination">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${TypeEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.type')}"
          key="type">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${AnfrageStatusEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.anfrageStatus')}"
          key="ok">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${VerarbeitungStatusEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.status')}"
          key="status">
        </isceco-multiselect-filter>
        <isceco-multiselect-filter
          .items="${this.errors}"
          label="${this.i18n.translate('table.header.errors')}"
          key="errors">
        </isceco-multiselect-filter>
        <isceco-checkbox-filter
          label="${this.i18n.translate('table.header.processed')}"
          key="processed">
        </isceco-checkbox-filter>
      </isceco-filter-group>

      <isceco-list id="queries-table"
                   update-event="${QueriesColumns.UPDATE_TABLE}"
                   .i18n="${this.i18n}"
                   .columns="${QueriesColumns.getCITESExportColumns()}"
                   .rowVariant="${new QueriesRowVariant()}"
                   .rowurl="${this._toDetail}"
                   .listService="${new QueriesListService(this.listUrl, this.module)}"
                   sort="time"
      ></isceco-list>

      ${CommentCell.renderDialog(this.i18n)}
    `
  }

  _toDetail = dto => `CITESExportDetail/${dto.id}`
}

customElements.define('management-frontend-cites-export-queries', CITESExport)
