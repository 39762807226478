import TranslatedEnum from './TranslatedEnum.js'

export default class AnfrageStatusEnum extends TranslatedEnum {
  static get KEYS() {
    return {
      true: true,
      false: false
    }
  }

  static get I18N() {
    return {
      de: {
        true: 'Angenommen',
        false: 'Abgelehnt'
      },
      fr: {
        true: 'Accepté',
        false: 'Refusé'
      },
      it: {
        true: 'Accettato',
        false: 'Respinto'
      }
    }
  }
}
