import ErrorUtil from '../../services/ErrorUtil'
import VerarbeitungStatusEnum from '../../enum/VerarbeitungStatusEnum'

export default class QueriesRowVariant {
  get(row) {
    return QueriesRowVariant.color(row)
  }

  static color(row) {
    if (row.ok) {
      if (row.status === VerarbeitungStatusEnum.KEYS.IN_QUEUE ||
        row.status === VerarbeitungStatusEnum.KEYS.VALIDATING ||
        this.hasErrors(row)) {
        return 'warning clickable'
      } else {
        return 'success clickable'
      }
    } else {
      return 'error clickable'
    }
  }

  static hasErrors(row) {
    if (!row.errors) {
      return false
    }

    let errors
    if (row.errors instanceof Array) {
      errors = row.errors.map(err => err.code)
        .filter(err => !ErrorUtil.NON_BREAKING_ERRORS.includes(err))
    } else {
      errors = row.errors.split(';')
        .filter(err => !ErrorUtil.NON_BREAKING_ERRORS.includes(err))
    }
    return errors.length > 0
  }
}
