import {html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Popup/Popup.js'
import '@isceco/widget-library2/basic-elements/Button/Button.js'
import '@isceco/widget-library2/basic-elements/ModalDialog/ModalDialog.js'
import '@isceco/widget-library2/basic-elements/Textarea/Textarea.js'
import QueriesService from '../../services/QueriesService.js'
import QueriesColumns from './QueriesColumns.js'

export default class CommentCell {
  render(i18n, cell, row) {
    const popup = cell ? html`
      <isceco-popup class="comment" style="text-align: left;">
        <isceco-button class="comment-show"
                       slot="wrapper"
                       icon="comment dots"
                       variant="tertiary"
                       size="small"
                       @click="${e => e.preventDefault()}"
        ></isceco-button>
        <div slot="content" style="white-space: pre-line;">${cell}</div>
      </isceco-popup>
    ` : ''
    return html`
      <div style="display: flex; justify-content: flex-end;">
        ${popup}
        <isceco-button class="edit"
                       icon="pen"
                       variant="tertiary"
                       size="small"
                       @click="${e => CommentCell.openCommentDialog(e, cell, row.id)}"
        ></isceco-button>
      </div>
    `
  }

  static openCommentDialog = (event, comment, id) => {
    event.preventDefault()

    const dialog = document.querySelector('#comment-dialog')
    const text = dialog.querySelector('#comment-text')
    text.value = comment
    text.name = id
    dialog.hidden = false
  }

  static closeCommentDialog = onSave => event => {
    if (event.detail.confirmed) {
      const text = document.querySelector('#comment-text')
      new QueriesService().update({id: text.name, comment: text.getValue()})
        .then(onSave)
    }

    event.target.hidden = true
  }

  static renderDialog = (i18n, onSave = _ => send(QueriesColumns.UPDATE_TABLE)) => html`
    <isceco-dialog id="comment-dialog"
                   hidden
                   header="${i18n.translate('comment.header')}"
                   confirm-button="${i18n.translate('comment.save')}"
                   cancel-button="${i18n.translate('comment.cancel')}"
                   @submit="${this.closeCommentDialog(onSave)}">
      <isceco-textarea id="comment-text" resize="auto" maxlength="1000"></isceco-textarea>
    </isceco-dialog>
  `
}
