import {css, html} from '@isceco/widget-library2/external/lit'
import '@isceco/widget-library2/basic-elements/Header/Header.js'
import '@isceco/widget-library2/basic-elements/LanguageSwitcher/LanguageSwitcher.js'
import '@isceco/widget-library2/basic-elements/Link/Link.js'
import '@isceco/widget-library2/basic-elements/ActionMenu/ActionMenu.js'
import WebComponent from '../../WebComponent.js'
import ConfigUtil from '../../services/ConfigUtil'


export default class Header extends WebComponent {

  get translationFile() {
    return './views/Header/i18n.json'
  }

  get css() {
    return css`
      .user-language {
        display: flex;
        align-items: end;
        flex-direction: row-reverse;
        gap: var(--isceco-space-200);
      }
    `
  }

  connectedCallback() {
    super.connectedCallback()
    this.logoutPage = ''
    new ConfigUtil().retrieveConfiguration()
      .then(config => {
        this.logoutPage = config.logoutPage
      })
    this.render()
  }

  getTemplate() {
    return html`
      <isceco-header text="${this.i18n.translate('header.text')}" shrink>
        <div class="user-language">
          <isceco-language></isceco-language>
          <div class="user-menu">
            <isceco-action-menu id="user-action-menu"
                                .items="${[
                                  {
                                    icon: 'sign out',
                                    text: this.i18n.translate('header.logout'),
                                    callback: _ => window.keycloak.logout({redirectUri: this.logoutPage})
                                  }
                                ]}">
              <isceco-link slot="wrapper"
                           text="${this.displayName()}"
                           icon="user circle outline"
              </isceco-link>
            </isceco-action-menu>
          </div>
        </div>
      </isceco-header>
    `
  }

  displayName = () => {
    if (!isEmpty(window.keycloak)) {
      return `${window.keycloak.tokenParsed.given_name} ${window.keycloak.tokenParsed.family_name}`
    } else {
      return ''
    }
  }
}

customElements.define('management-frontend-header', Header)
