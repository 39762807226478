import TranslatedEnum from './TranslatedEnum.js'

export default class ModuleEnum extends TranslatedEnum {
  static get KEYS() {
    return {
      CITES_EXPORT: 'CITES_EXPORT',
      CITES_IMPORT: 'CITES_IMPORT',
      IUU: 'IUU',
      EDAV_INPEC: 'EDAV_INPEC',
      EDAV_TRACES: 'EDAV_TRACES',
      EDAV_WHITELIST: 'EDAV_WHITELIST',
      PHYTO: 'PHYTO'
    }
  }

  static get ADDITIONAL_KEYS() {
    return {
      EDAV: 'EDAV',
      INPEC: 'INPEC',
      TRACES: 'TRACES',
      WHITELIST: 'WHITELIST'
    }
  }

  static get I18N() {
    return {
      de: {
        CITES_EXPORT: 'CITES Export',
        CITES_IMPORT: 'CITES Import',
        IUU: 'IUU',
        EDAV_INPEC: 'EDAV EU & NTLM - Inpec',
        EDAV_TRACES: 'EDAV EU & NTLM - Traces',
        EDAV_WHITELIST: 'EDAV EU & NTLM - Paket Privat',
        PHYTO: 'Phyto',

        EDAV: 'EDAV EU & NTLM',
        INPEC: 'Inpec',
        TRACES: 'Traces',
        WHITELIST: 'Paket Privat'
      },
      fr: {
        CITES_EXPORT: 'Exportation CITES',
        CITES_IMPORT: 'CITES Import fr',
        IUU: 'IUU fr',
        EDAV_INPEC: 'EDAV EU & NTLM - Inpec it',
        EDAV_TRACES: 'EDAV EU & NTLM - Traces it',
        EDAV_WHITELIST: 'EDAV EU & NTLM - Paket Privat it',
        PHYTO: 'Phyto fr',

        EDAV: 'EDAV EU & NTLM fr',
        INPEC: 'Inpec fr',
        TRACES: 'Traces fr',
        WHITELIST: 'Paket Privat fr'
      },
      it: {
        CITES_EXPORT: 'Esportazione CITES',
        CITES_IMPORT: 'CITES Import it',
        IUU: 'IUU it',
        EDAV_INPEC: 'EDAV EU & NTLM - Inpec fr',
        EDAV_TRACES: 'EDAV EU & NTLM - Traces fr',
        EDAV_WHITELIST: 'EDAV EU & NTLM - Paket Privat fr',
        PHYTO: 'Phyto it',

        EDAV: 'EDAV EU & NTLM it',
        INPEC: 'Inpec it',
        TRACES: 'Traces it',
        WHITELIST: 'Paket Privat it'
      }
    }
  }

  static EDAV_ITEMS() {
    return this.formatItems([
      ModuleEnum.KEYS.EDAV_INPEC,
      ModuleEnum.KEYS.EDAV_TRACES,
      ModuleEnum.KEYS.EDAV_WHITELIST
    ])
  }
}
