import TranslatedEnum from './TranslatedEnum.js'

export default class VerarbeitungStatusEnum extends TranslatedEnum {
  static get KEYS() {
    return {
      IN_QUEUE: 'IN_QUEUE',
      VALIDATING: 'VALIDATING',
      COMPLETED_SUCCESS: 'COMPLETED_SUCCESS',
      COMPLETED_FAILED: 'COMPLETED_FAILED',
      CANCELLED: 'CANCELLED'
    }
  }

  static get I18N() {
    return {
      de: {
        IN_QUEUE: 'In Queue',
        VALIDATING: 'Wird verarbeitet',
        COMPLETED_SUCCESS: 'Verarbeitet (keine Fehler)',
        COMPLETED_FAILED: 'Verarbeitet (mit Fehler)',
        CANCELLED: 'Storniert'
      },
      fr: {
        IN_QUEUE: 'In Queue fr',
        VALIDATING: 'Wird verarbeitet fr',
        COMPLETED_SUCCESS: 'Verarbeitet (keine Fehler) fr',
        COMPLETED_FAILED: 'Verarbeitet (mit Fehler) fr',
        CANCELLED: 'Storniert fr'
      },
      it: {
        IN_QUEUE: 'In Queue it',
        VALIDATING: 'Wird verarbeitet it',
        COMPLETED_SUCCESS: 'Verarbeitet (keine Fehler) it',
        COMPLETED_FAILED: 'Verarbeitet (mit Fehler) it',
        CANCELLED: 'Storniert it'
      }
    }
  }
}
