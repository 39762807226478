import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'

export default class Landing extends WebComponent {

  get translationFile() {
    return './views/Landing/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.render()
  }

  getTemplate() {
    return html`
      This is your landing page
    `
  }

}
customElements.define('management-frontend-landing', Landing)
