export default class Navigation {

  constructor() {
    window.onhashchange = _ => window.send(Navigation.NAVIGATION_HAPPEND, getNavigationHash())
  }

  static get NAVIGATION_HAPPEND() {
    return 'management-frontend-navigation-happened'
  }
}
const navigation = new Navigation()
