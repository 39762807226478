import Authorization from './Authorization.js'

export default class AuthorizationFilter {
  static get loggedInUserIsAdmin() {
    return window.hasRole(Authorization.ROLES.admin)
  }

  constructor() {
    this.roles = {
      admin: AuthorizationFilter.loggedInUserIsAdmin,
      blvcites: window.hasRole(Authorization.ROLES.blvcites),
      blviuu: window.hasRole(Authorization.ROLES.blviuu),
      blvedaveu: window.hasRole(Authorization.ROLES.blvedaveu),
      blw: window.hasRole(Authorization.ROLES.blw),
      autorisaziun: window.hasRole(Authorization.ROLES.autorisaziun)
    }
  }

  viewAllowed(name) { // NOSONAR complexity: too many returns
    switch (name) {
      case '':
      case 'Module':
        return this.roles.admin || this.roles.blvcites || this.roles.blviuu || this.roles.blvedaveu || this.roles.blw // NOSONAR too many operators
      case 'CITESExport':
      case 'CITESExportDetail':
        return this.roles.admin || this.roles.blvcites
      case 'EDAV':
      case 'EDAVDetail':
      case 'NTLM':
      case 'NTLMDetail':
        return this.roles.admin || this.roles.blvedaveu
      case 'IUU':
      case 'IUUDetail':
        return this.roles.admin || this.roles.blviuu
      case 'Phyto':
      case 'PhytoDetail':
        return this.roles.admin || this.roles.blw
      default:
        return false
    }
  }
}
