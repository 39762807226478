import BaseService from '../BaseService.js'

export default class QueriesService extends BaseService {
  constructor() {
    super('abfragen')
  }

  async listByModule(path, module) {
    const params = new URLSearchParams()
    if (module instanceof Array) {
      module.forEach(m => params.append('module', m))
    } else {
      params.append('module', module)
    }
    return this.list(`${path}?${params.toString()}`)
  }
}
