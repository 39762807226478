import TranslatedEnum from './TranslatedEnum.js'

export default class TypeEnum extends TranslatedEnum {
  static get KEYS() {
    return {
      BINDING: 'BINDING',
      NON_BINDING: 'NON_BINDING',
      CANCELLATION: 'CANCELLATION'
    }
  }

  static get I18N() {
    return {
      de: {
        BINDING: 'Verbindlich',
        NON_BINDING: 'Unverbindlich',
        CANCELLATION: 'Storniert'
      },
      fr: {
        BINDING: 'Contraignant',
        NON_BINDING: 'Non contraignant',
        CANCELLATION: 'Annulé'
      },
      it: {
        BINDING: 'Vincolante',
        NON_BINDING: 'Non vincolante',
        CANCELLATION: 'Stornato'
      }
    }
  }
}
