import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .module-group {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: var(--isceco-space-300);
    margin-bottom: var(--isceco-space-300);
  }
  
  .module {
    flex: 1;
    min-width: 200px;
    white-space: nowrap;
  }
`
