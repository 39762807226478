export default class TranslatedEnum {
  static get KEYS() {
    return {}
  }

  static get I18N() {
    return {
      de: {},
      fr: {},
      it: {}
    }
  }

  static ITEMS() {
    return this.formatItems(Object.values(this.KEYS))
  }

  static formatItems(list) {
    const lang = getLanguage()
    return list
      .map(key => ({
        value: key,
        name: this.I18N[lang][key]
      }))
  }

  static translate(key) {
    return this.I18N[getLanguage()][key]
  }
}
