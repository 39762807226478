import WebComponent from '../../WebComponent.js'
import ModuleService from '../../services/ModuleService.js'
import {html} from '@isceco/widget-library2/external/lit'
import ModuleEnum from '../../enum/ModuleEnum.js'
import ModuleColumns from './ModuleColumns.js'
import '@isceco/widget-library2/basic-elements/Title/Title.js'
import '@isceco/widget-library2/basic-elements/Switch/Switch.js'
import '@isceco/widget-library2/basic-elements/Filter/FilterGroup.js'
import '@isceco/widget-library2/basic-elements/Filter/DateFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/StringFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/MultiselectFilter.js'
import '@isceco/widget-library2/basic-elements/Filter/CheckboxFilter.js'
import '@isceco/widget-library2/basic-elements/List/List.js'
import ModuleCss from './ModuleCss.js'

export default class Module extends WebComponent {
  get css() {
    return ModuleCss
  }

  get translationFile() {
    return './views/Module/i18n.json'
  }

  connectedCallback() {
    super.connectedCallback()
    this.moduleService = new ModuleService()
    this.updateTableEvent = 'update-module-history-table'
    this.modules = {}
    this.reload()
  }

  reload() {
    Promise.all([this.moduleService.getBackendListUrl('history'), this.moduleService.list()])
      .then(([url, json]) => {
        this.historyUrl = url
        this.modules = json.reduce((acc, mod) => ({...acc, [mod.name]: mod.active}), {})
        this.render()
      })
  }

  getTemplate() {
    return html`
      <isceco-title text="${this.i18n.translate('page.title')}"></isceco-title>

      <div class="module-group">
        <div class="module">
          <isceco-title text="${ModuleEnum.translate(ModuleEnum.KEYS.CITES_EXPORT)}" size="medium"></isceco-title>
          ${this.renderSwitch(ModuleEnum.KEYS.CITES_EXPORT, ModuleEnum.ADDITIONAL_KEYS.INPEC)}
        </div>
        <div class="module">
          <isceco-title text="${ModuleEnum.translate(ModuleEnum.KEYS.CITES_IMPORT)}" size="medium"></isceco-title>
          ${this.renderSwitch(ModuleEnum.KEYS.CITES_IMPORT, ModuleEnum.ADDITIONAL_KEYS.INPEC)}
        </div>
        <div class="module">
          <isceco-title text="${ModuleEnum.translate(ModuleEnum.KEYS.IUU)}" size="medium"></isceco-title>
          ${this.renderSwitch(ModuleEnum.KEYS.IUU, ModuleEnum.ADDITIONAL_KEYS.INPEC)}
        </div>
        <div class="module">
          <isceco-title text="${ModuleEnum.translate(ModuleEnum.ADDITIONAL_KEYS.EDAV)}" size="medium"></isceco-title>
          ${this.renderSwitch(ModuleEnum.KEYS.EDAV_INPEC, ModuleEnum.ADDITIONAL_KEYS.INPEC)}
          ${this.renderSwitch(ModuleEnum.KEYS.EDAV_TRACES, ModuleEnum.ADDITIONAL_KEYS.TRACES)}
          ${this.renderSwitch(ModuleEnum.KEYS.EDAV_WHITELIST, ModuleEnum.ADDITIONAL_KEYS.WHITELIST)}
        </div>
        <div class="module">
          <isceco-title text="${ModuleEnum.translate(ModuleEnum.KEYS.PHYTO)}" size="medium"></isceco-title>
          ${this.renderSwitch(ModuleEnum.KEYS.PHYTO, ModuleEnum.ADDITIONAL_KEYS.TRACES)}
        </div>
      </div>


      <isceco-filter-group>
        <isceco-date-filter
          label="${this.i18n.translate('table.header.dateTime')}"
          key="dateTime">
        </isceco-date-filter>
        <isceco-string-filter
          minchars="2"
          label="${this.i18n.translate('table.header.byUser')}"
          key="byUser">
        </isceco-string-filter>
        <isceco-multiselect-filter
          .items="${ModuleEnum.ITEMS()}"
          label="${this.i18n.translate('table.header.module')}"
          key="module">
        </isceco-multiselect-filter>
        <isceco-checkbox-filter
          label="${this.i18n.translate('table.header.active')}"
          key="active">
        </isceco-checkbox-filter>
      </isceco-filter-group>

      <isceco-list id="module-history-table"
                   update-event="${this.updateTableEvent}"
                   url="${this.historyUrl}"
                   max="5"
                   sort="dateTime"
                   .i18n="${this.i18n}"
                   .columns="${ModuleColumns.getColumns()}"
      ></isceco-list>
    `
  }

  renderSwitch = (module, label) =>
    html`
      <isceco-switch id="module-${module}"
                     label="${ModuleEnum.translate(label)}"
                     ?value="${this.modules[module]}"
                     @change="${e => this.updateModule(module, e)}"
      ></isceco-switch>
    `

  updateModule = (name, event) => {
    this.moduleService.create({active: event.detail.value}, `/${name}`)
      .then(_ => {
        const translationKey = event.detail.value ? 'module.success.activated.text' : 'module.success.deactivated.text'
        const type = event.detail.value ? 'success' : 'warning'
        showAlert(
          this.i18n.translate('module.success.title'),
          this.i18n.translate(translationKey, {name: ModuleEnum.translate(name)}),
          type
        )
        send(this.updateTableEvent)
      })
      .catch(_ => {
        event.target.value = !event.target.getValue()
      })
  }
}
customElements.define('management-frontend-module', Module)
