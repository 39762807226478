export default class Authorization {
  static get ROLES() {
    return {
      admin: 'BLV-elbetz.Admin',
      blvcites: 'BLV-elbetz.Sachbearbeiter_BLV_CITES',
      blviuu: 'BLV-elbetz.Sachbearbeiter_BLV_IUU',
      blvedaveu: 'BLV-elbetz.Sachbearbeiter_BLV_EDAV-EU',
      blw: 'BLV-elbetz.Sachbearbeiter_BLW',
      autorisaziun: 'BLV-elbetz.Client_Autorisaziun'
    }
  }
}
