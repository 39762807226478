import {css} from '@isceco/widget-library2/external/lit'

export default css`
  .detail-content {
    display: flex;
    flex-direction: row;
    gap: var(--isceco-space-500);
  }

  .detail-content > div {
    flex: 1;
  }

  .detail-item {
    margin: var(--isceco-space-300) 0;
  }

  .detail-item > p {
    margin: var(--isceco-space-100) 0 0;
    white-space: pre-line;
  }

  #status-comment {
    float: right;
  }

  #status-processed {
    font-weight: bold;
  }

  /* display status with color */

  .clickable {
    margin: 0 calc(var(--isceco-space-300) * -1);
    padding: 1px var(--isceco-space-300);
    border-radius: var(--isceco-space-200);
  }

  .success {
    background-color: var(--isceco-color-green-100);
  }

  .error {
    background-color: var(--isceco-color-red-100);
  }

  .warning {
    background-color: var(--isceco-color-yellow-100);
  }

  @media (max-width: 991.98px) {
    .detail-content {
      display: block;
    }
  }
`
