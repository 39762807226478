import {html} from '@isceco/widget-library2/external/lit'
import WebComponent from '../../WebComponent.js'
import Navigation from '../Navigation/Navigation.js'
import NoContent from '../NoContent/NoContent.js'
import Landing from '../Landing/Landing.js'
import NotAuthorized from '../NotAuthorized/NotAuthorized.js'
import AuthorizationFilter from '../Authorization/AuthorizationFilter.js'
import Module from '../Module/Module.js'
import CITESExportDetail from '../CITESExport/Detail/CITESExportDetail.js'
import CITESExport from '../CITESExport/Queries/CITESExport.js'
import EDAV from '../EDAV/Queries/EDAV.js'
import EDAVDetail from '../EDAV/Detail/EDAVDetail.js'
import NTLM from '../NTLM/Queries/NTLM.js'
import NTLMDetail from '../NTLM/Detail/NTLMDetail.js'
import IUU from '../IUU/Queries/IUU.js'
import IUUDetail from '../IUU/Detail/IUUDetail.js'
import Phyto from '../Phyto/Queries/Phyto'
import PhytoDetail from '../Phyto/Detail/PhytoDetail'

export default class MainView extends WebComponent {

  constructor() {
    super()
  }

  connectedCallback() {
    super.connectedCallback()
    this.oldChild = null
    this.navigationListener = e => {
      // split is used if we have vertical submenu navigation
      this.loadView(e.detail)
    }
    document.addEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)

    this.loadView(getNavigationHash())
  }

  disconnectedCallback() {
    super.disconnectedCallback()
    this.removeEventListener(Navigation.NAVIGATION_HAPPEND, this.navigationListener)
  }

  loadView(linkName) {
    if (isEmpty(linkName)) {
      this.redirectToDefault()
    }
    const newChild = this.returnNewChild(linkName)
    if (this.oldChild) {
      this.root.replaceChild(newChild, this.oldChild)
    } else {
      this.root.appendChild(newChild)
    }

    this.oldChild = newChild
  }

  get views() {
    return {
      'Landing': Landing,
      'CITESExport': CITESExport,
      'CITESExportDetail': CITESExportDetail,
      'EDAV': EDAV,
      'EDAVDetail': EDAVDetail,
      'NTLM': NTLM,
      'NTLMDetail': NTLMDetail,
      'IUU': IUU,
      'IUUDetail': IUUDetail,
      'Phyto': Phyto,
      'PhytoDetail': PhytoDetail,
      'Module': Module
    }
  }

  returnNewChild(linkName) { //NOSONAR
    if (!new AuthorizationFilter().viewAllowed(linkName)) {
      return new NotAuthorized()
    }

    if (this.views.hasOwnProperty(linkName)) {
      return new this.views[linkName]()
    } else {
      return new NoContent()
    }
  }

  redirectToDefault() {
    const allowed = Object.keys(this.views)
      .filter(path => new AuthorizationFilter().viewAllowed(path))
    if (allowed.length > 0) {
      navigate({to: allowed[0]})
    }
  }

  getTemplate() {
    return html`management-frontend-main-view`
  }
}

customElements.define('management-frontend-main-view', MainView)
