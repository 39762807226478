import ListService from '@isceco/widget-library2/basic-elements/List/ListService.js'

export default class QueriesListService extends ListService {
  constructor(url, module, sectorId) {
    super(url)
    this.module = module
    this.sectorId = sectorId
  }

  async list(queryParams) {
    queryParams.module = this.module
    if (this.sectorId) {
      queryParams.sectorId = this.sectorId
    }
    return super.list(queryParams)
  }
}
